.admin_row{
    justify-content: center;
}
.admin_card{
    padding: 10%;
}

/* App.css */
body, html, #root {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
  }
  .App{
    height: 100%;
  }
  label{
    font-size: 15px;
  }
  .layout {
    display: flex;
    /* height: 100%; */
  }
  
  .sidebar {
    width: 200px;
    background-color: #333;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin: 15px 0;
  }
  
  .sidebar ul li a {
    color: white;
    text-decoration: none;
  }
  
  .sidebar ul li a:hover {
    text-decoration: underline;
  }
  .cart-button-row{
    text-align: center;
  }
  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .cart-button{
    background: #f4d00d;
    border: none;
    padding: 6px;
    font-size: 10px;
  }
  
  .navbar {
    background-color: #007bff;
    color: white;
    padding: 10px;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .footer {
    background-color: #f1f1f1;
    text-align: center;
    padding: 10px;
  }
  .text_for_notebook{
    border: 1px solid black !important;
    width: 46% !important;
    height: 32px !important;

  }
  .font_for_notebook{
    border: 1px solid black !important;
    width: 46% !important;
    height: 32px !important;

  }
  .admin-note{
    color:red;
    font-size: 14px;
  }
  