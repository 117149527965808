.cart_image{
    width: 36%;
}
.summary_div{
    background-color: #e4e3e3;
    padding: 18px;
    height: fit-content;
}
/* .order_summary_title{
    text-align: center;
    padding: 14px;
} */
.order_summary_title {
    font-size: 17px;
    text-align: center;
    padding: 14px;
}
.continue_shopping{
    white-space: nowrap;
    background: #f4d00d;
    border: none;
    padding: 5px;
    font-size: 15px;
}

.checkout_row2{
    justify-content: center;
    padding: 20px;
}